import {
  Avatar,
  Box,
  // Button,
  // Checkbox,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { Button as BaseUiButton, Text } from "@paro.io/base-ui";
import { green, grey, red, yellow } from "@material-ui/core/colors";
import { CheckCircleOutline, Delete, ErrorOutline } from "@material-ui/icons";
import { Modal, Spinner } from "react-bootstrap";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CartContext } from "../components/CartContext";
import FeedbackForm from "../components/FeedbackForm";
import TopSection from "../components/TopSection";
import { Auth0Context } from "../contexts/Auth0Context";
import {
  getCartDetails,
  getFindFreelancersCartFn,
  getFLFullProfileViews,
  getFreelancerProfilesByIdsFn,
  getProposal,
  getProposalMmData,
  removeFindFlFromCartFn,
  removeFreelancer,
  removeFromCartFn,
  saveFaFResult,
  // submitAppFeedback,
  toggleJobBoard,
  updateCartCheckout,
  // toggleJobBoard,
} from "../services/apiService";
import {
  getFreelancerDetailsFn,
  sendPlatformEmails,
} from "../utils/platformService";
import CustomSpinner from "../components/CustomSpinner";
import { useSnackbar } from "notistack";
import FeedbackFormComponent from "../components/FeedbackFormComponent";
import {
  IS_PROD_ENVIRONMENT,
  OPPORTUNITY_TYPES,
  REMOVE_REASONS,
} from "../data/appConstants";
// import ConfirmationModal from "../components/ConfirmationModal";
import FreelancerVote from "../components/FreelancerVote";
import FreelancerCards from "../components/FreelancerCards";
import { getFlsFeedback } from "../utils/mmService";
import ToggleJobBoard from "../components/ToggleJobBoard";

const useStyles = makeStyles((theme) => ({
  checkout: {
    padding: theme.spacing(2, 5, 2, 5),
  },
  checkedout: {
    padding: theme.spacing(2, 5, 2, 5),
    width: "83.33%",
  },
  warning: {
    margin: theme.spacing(2, 0, 1, 0),
    padding: theme.spacing(1),
    backgroundColor: yellow[300],
  },
  error: {
    margin: theme.spacing(2, 0, 1, 0),
    padding: theme.spacing(1),
    backgroundColor: red[100],
  },
  submitted: {
    margin: theme.spacing(2, 0, 1, 0),
    padding: theme.spacing(1),
    backgroundColor: green[100],
  },
  list: {
    paddingTop: "12px",
  },
  listItem: {
    margin: theme.spacing(0, 0, 0.5, 0),
  },
  button: {
    width: "220px",
    margin: theme.spacing(0, 0, 2, 0),
  },
  modal: {
    "& .modal-dialog": {
      minWidth: "900px",
    },
  },
  cartIcon: {
    height: "50px",
    width: "50px",
  },
  percentage: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#059669",
    padding: theme.spacing(0),
  },
  primaryText: {
    display: "inline-block",
  },
  buttonSize: {
    fontSize: "14px",
    fontWeight: "500",
    height: "34px",
    textTransform: "uppercase",
    padding: theme.spacing(0.5, 1, 0.5, 1),
    margin: theme.spacing(0, 0.25),
    backgroundColor: grey[300],
  },
}));
export default function Checkout(props) {
  const classes = useStyles();
  const { proposalId } = useParams();
  const {
    cartContext,
    globalMatchCartContext,
    proposalContext,
    flViewContext,
  } = useContext(CartContext);
  const [cart, setCart] = cartContext;
  const [, setMatchIds] = globalMatchCartContext;
  const [proposalData, setProposalData] = proposalContext;
  const [, setTemplates] = flViewContext;

  const [loadingData, setLoadingData] = useState(true);
  const { user } = useContext(Auth0Context);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [onJobBoard, setOnJobBoard] = useState(true);
  const [showFullProfile, setShowFullProfile] = useState(null);
  const [, setProposal] = useState(
    proposalData.filter((p) => p.id === proposalId)
  );
  const { enqueueSnackbar } = useSnackbar();
  const [currentOpportunityType, setCurrentOpportunityType] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [failedFls, setFailedFls] = useState([]);

  const history = useHistory();

  const { firstName, lastName } =
    user[`${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`];
  const userName = firstName + " " + lastName;

  const captureErrors = (res) => {
    const failedDetails = res.reduce((acc, t) => {
      if (t.data.errors) {
        const errorDetails = t.data.errors[0]?.extensions;
        const { freelancerId } = errorDetails;
        const f = cart.find((c) => c.id === freelancerId);
        const reasons = [];
        if (!errorDetails?.isAvailable) {
          reasons.push("Freelancer is Not Available");
        }
        if (!errorDetails?.isNotSuspended) {
          reasons.push("Freelancer is Suspended");
        }
        if (!errorDetails?.statusIsAcceptedOrActive) {
          reasons.push("Freelancer is not in Accepted/Active Status");
        }
        return [
          ...acc,
          {
            freelancerId,
            name: isMM ? f.FL_Name : f.name,
            reasons: reasons.join(" OR "),
          },
        ];
      } else {
        return [...acc];
      }
    }, []);
    setFailedFls(failedDetails);
  };

  const submitFreelancers = async () => {
    if (isMM) {
      setShowFeedbackForm(true);
    } else {
      const onSubmitOfFreelancers = async () => {
        if (IS_PROD_ENVIRONMENT) {
          setLoadingButton(true);
          const selectedFreelancersId = cart.map((c) => c.id);
          const res = await sendPlatformEmails(
            proposalId,
            selectedFreelancersId,
            true,
            "Find a Freelancer"
          );
          captureErrors(res);
          /* if (!!failedFls?.length) {
            await submitAppFeedback({
              relatedTo: `Matching Checkout`,
              comments: JSON.stringify(failedFls),
              userId: user.auth0UserId,
              userName,
              url: window.location.href,
              context: JSON.stringify({
                proposalId,
                cart,
                currentOpportunityType,
              }),
            });
          } */

          await toggleJobBoard(proposalId, onJobBoard);
          await saveFaFResult({
            proposalId: parseInt(proposalId),
            freelancerIds: "" + selectedFreelancersId,
            userId: user.auth0UserId,
            opportunityTypeMM: currentOpportunityType,
          });
          await updateCartCheckout(
            parseInt(proposalId),
            user.auth0UserId,
            true
          );

          setLoadingButton(false);
          setCart([]);
          setSubmitted(true);
        } else {
          console.log("Skipping sending Emails to Freelancers - QA env");
          enqueueSnackbar(`Skipping sending Emails to Freelancers - QA env`, {
            variant: "warning",
          });
        }
      };
      onSubmitOfFreelancers();
    }
  };

  const removeFromCart = async (f) => {
    const data = {
      proposalId: parseInt(proposalId),
      userId: user.auth0UserId,
      matchId: f.matchId,
      freelancerId: f.flID,
    };
    await removeFromCartFn(data);
    setCart((currentState) => currentState.filter((c) => c.flID !== f.flID));
  };

  const onConfirmRemoveFromCartFn = (data, other = false) => {
    removeFreelancer({
      userId: user.auth0UserId,
      userName,
      freelancerId: showDetails.flID,
      matchId: showDetails.matchId,
      proposalId: parseInt(proposalId),
      reason:
        "" +
        (other
          ? [...data.predefinedReasons, data.otherReason]
          : data.predefinedReasons),
      comments: data.comments,
    })
      .then(() => {
        removeFromCart(showDetails);
        setShowDetails(null);
      })
      .catch(() => {
        enqueueSnackbar(`Unable to remove Freelancer ${data.flID}!`, {
          variant: "error",
        });
        setShowDetails(null);
      });
  };

  const handleCloseFeedbackForm = () => {
    setShowFeedbackForm(false);
  };

  const getCartFreelancers = useCallback(async () => {
    if (
      ![
        OPPORTUNITY_TYPES.NewBusiness,
        OPPORTUNITY_TYPES.CrossSell,
        OPPORTUNITY_TYPES.WL,
      ].includes(currentOpportunityType)
    ) {
      const res1 = await getFindFreelancersCartFn(
        parseInt(proposalId),
        user.auth0UserId
      );
      const cartDetails1 = res1.data.data.getFindFreelancersCart?.freelancerIds;
      if (cartDetails1?.length < 0) {
        const tempCart1 = [...new Set(cartDetails1.split(",").map((c) => +c))];
        const response = await getFreelancerProfilesByIdsFn(tempCart1);
        setCart(response.data.data.getFreelancerProfilesByIds);
      }
    } else {
      const res2 = await getCartDetails(parseInt(proposalId), user.auth0UserId);
      const cartDetails2 = res2.data.data.getCartDetails;
      if (!cartDetails2) {
        return setSubmitted(true);
      }
      const mIds = cartDetails2.map((c) => c.matchId);
      setMatchIds(mIds);
      const propFlData = await Promise.all(
        cartDetails2.map((d) => getFreelancerDetailsFn(d))
      );

      const tempCart2 = await getFlsFeedback(propFlData, mIds);
      setCart(tempCart2);
    }

    const proposalRes = await getProposal(parseInt(proposalId));
    let proposal = proposalRes.data.data.getProposal;
    setProposalData((p) => {
      p.push(proposal);
      return p;
    });
    setProposal(proposal);
    const templates = await getFLFullProfileViews(user.auth0UserId);
    let templatesData = templates.data.data.getFLProfileSettings;
    templatesData.map((t) => (t.fields = JSON.parse(t.fields)));
    setTemplates(templatesData);

    setLoadingData(false);
  }, [
    currentOpportunityType,
    proposalId,
    setCart,
    setMatchIds,
    setProposalData,
    setTemplates,
    user.auth0UserId,
  ]);

  const getProposalMmDataFn = useCallback(async () => {
    const {
      data: {
        data: { getProposal: data },
      },
    } = await getProposalMmData(parseInt(proposalId));
    setCurrentOpportunityType(data?.opportunityTypeMM);
    setOnJobBoard(
      [
        OPPORTUNITY_TYPES.NewBusiness,
        OPPORTUNITY_TYPES.CrossSell,
        OPPORTUNITY_TYPES.WL,
      ].includes(data?.opportunityTypeMM)
    );

    if (!cart.length && !submitted) {
      getCartFreelancers();
    } else {
      setLoadingData(false);
    }
  }, [cart.length, getCartFreelancers, proposalId, submitted]);

  const removeFindFl = async (freelancer) => {
    await removeFindFlFromCartFn({
      proposalId: +proposalId,
      userId: user.auth0UserId,
      freelancerId: freelancer.id,
    });
    setCart((currentState) =>
      currentState.filter((c) => c.flID !== freelancer.id)
    );
    enqueueSnackbar(`Removed ${freelancer.name} from Cart!`, {
      variant: "warning",
    });
  };
  useEffect(() => {
    getProposalMmDataFn();
  }, [getProposalMmDataFn]);

  const backToProposal = () => {
    history.push(`/`);
  };

  const navigateToPlatform = () => {
    window.location.href =
      process.env.REACT_APP_PARO_APP_URL + "/internal/am/proposals";
  };

  const navigateToCommunicationHub = () => {
    window.location.href =
      process.env.REACT_APP_INTERNAL_PORTAL +
      "/communication-hub/" +
      proposalId;
  };

  const isMM = [
    OPPORTUNITY_TYPES.NewBusiness,
    OPPORTUNITY_TYPES.CrossSell,
    OPPORTUNITY_TYPES.WL,
  ].includes(currentOpportunityType);

  // If Full Profile is clicked
  if (!loadingData && !submitted && showFullProfile) {
    return (
      <>
        <TopSection
          proposalId={proposalId}
          checkout
          faf={!isMM}
          showFullProfile={showFullProfile}
          goBack={() => {
            setShowFullProfile(null);
          }}
          showCart={true}
        />
        <FreelancerCards
          data={showFullProfile}
          proposalNotes={proposalData.notes}
          proposalId={parseInt(proposalId)}
          tabIndex={0}
        />
      </>
    );
  }
  return (
    <>
      <TopSection
        proposalId={proposalId}
        checkout
        faf={!isMM}
        showFullProfile={showFullProfile}
        goBack={() => {
          setShowFullProfile(null);
        }}
      />
      {loadingData && <CustomSpinner>Fetching Cart</CustomSpinner>}
      {!loadingData && !submitted && !showFullProfile && (
        <Box className={classes.checkout}>
          <h4>
            You have selected {cart.length} of 8 freelancers for proposal:{" "}
            {proposalId}
          </h4>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              {cart.length > 8 && (
                <Paper className={classes.warning} variant="outlined">
                  <ErrorOutline />
                  <span className="ml-2">
                    Please submit only 8 freelancers at the time
                  </span>
                </Paper>
              )}
              <List className={classes.list}>
                {cart.map((f) => (
                  <Paper className={classes.listItem}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <img src={f.imageUrl} alt="" width="100%" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div className={classes.primaryText}>
                            {isMM ? f.FL_Name : f.name}
                          </div>
                        }
                        secondary={
                          <div>
                            {isMM ? f.FL_Service : f.serviceLine}
                            {isMM && (
                              <Text className={classes.percentage}>
                                {" "}
                                {Math.round(f.matchPercent)}% Match{" "}
                              </Text>
                            )}
                          </div>
                        }
                      />
                      {isMM && (
                        <>
                          <span id="mm-cartFreelancer-fullProfile">
                            <BaseUiButton
                              label="Full Profile"
                              onClick={() => setShowFullProfile(f)}
                              className={`${classes.buttonSize}`}
                              size="medium"
                            />
                          </span>
                          <FreelancerVote
                            proposalId={parseInt(proposalId)}
                            freelancerId={f.flID}
                          />
                        </>
                      )}
                      <span id="mm-cartFreelancer-remove">
                        <BaseUiButton
                          label="Remove"
                          onClick={() => {
                            isMM ? setShowDetails(f) : removeFindFl(f);
                          }}
                          className={classes.buttonSize}
                          iconLeft={<Delete size="sm" />}
                        />
                      </span>
                    </ListItem>
                  </Paper>
                ))}
              </List>
            </Grid>
            <Grid
              item
              xs={2}
              justify="center"
              align="center"
              className={"pt-4"}
            >
              <div>
                <ToggleJobBoard
                  onJobBoard={onJobBoard}
                  setOnJobBoard={setOnJobBoard}
                  disabled={[
                    OPPORTUNITY_TYPES.NewBusiness,
                    OPPORTUNITY_TYPES.BuyOut,
                    OPPORTUNITY_TYPES.Upsell,
                  ].includes(currentOpportunityType)}
                />
              </div>

              {loadingButton ? (
                <div className="bg-primary text-white py-2 px-8 font-bold text-center rounded mx-2">
                  <Spinner animation="border" size="sm" />
                </div>
              ) : (
                <span id="mm-cart-continue">
                  <BaseUiButton
                    color="primary"
                    label={"Continue"}
                    onClick={submitFreelancers}
                    disabled={cart.length > 8 || (!isMM && cart.length < 1)}
                  />
                </span>
              )}
            </Grid>
          </Grid>
          <Modal
            show={showFeedbackForm}
            onHide={handleCloseFeedbackForm}
            // centered
            className={classes.modal}
          >
            <Modal.Header closeButton />
            <FeedbackForm
              proposalId={proposalId}
              closeFeedbackForm={handleCloseFeedbackForm}
              cartSubmitted={setSubmitted}
              onJobBoard={onJobBoard}
              captureErrors={captureErrors}
            />
          </Modal>
          <Modal
            show={!!showDetails}
            onHide={() => setShowDetails(null)}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Remove Freelancer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FeedbackFormComponent
                onSubmitFn={onConfirmRemoveFromCartFn}
                question="What are the reasons to remove this Freelancer?"
                options={REMOVE_REASONS}
                newReasonPlaceholder="i.e. Lack of experience, etc."
                submitButtonText="REMOVE"
                commentsHeading="Briefly explain your experience working with this freelancer"
                submittingText="Removing from cart"
              />
            </Modal.Body>
          </Modal>
        </Box>
      )}
      {!loadingData && submitted && !showFullProfile && (
        <>
          <Box className={classes.checkedout}>
            <span>
              Thank you for submitting freelancers for proposal: {proposalId}
            </span>
            <Paper className={classes.submitted} variant="outlined">
              <CheckCircleOutline />
              <span className="ml-2">
                {/* // TODO COMM HUB CHANGES  */}
                {/* The Freelancers you selected have been notified via an interest
                email. Check the proposal in the platform to continue. */}
                The Experts you selected have been added to the Communication
                Hub. To configure and send email communication please proceed to
                Communication Hub
              </span>
            </Paper>
            {failedFls.length > 0 && (
              <Paper className={classes.error} variant="outlined">
                <ErrorOutline />
                <span className="ml-2">
                  Error in adding following Freelancers to Communication Hub
                </span>
                <table className="error-table mx-4 my-4">
                  <thead>
                    <th className="font-bold">ID</th>
                    <th className="font-bold">Name</th>
                    <th className="font-bold">Reason</th>
                  </thead>
                  <tbody>
                    {failedFls.map((failedFl) => (
                      <tr key={failedFl.freelancerId} className="p-2">
                        <td>{failedFl.freelancerId}</td>
                        <td>{failedFl.name}</td>
                        <td>
                          Unable to create communication - {failedFl.reasons}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Paper>
            )}
            <span id="mm-checkout-home">
              <BaseUiButton
                onClick={backToProposal}
                className="mr-2 my-3"
                color="primary"
                label="BACK TO HOME"
              />
            </span>
            <span id="mm-checkout-continue">
              <BaseUiButton
                disabled
                onClick={navigateToPlatform}
                className="mr-2 my-3"
                color="primary"
                label="CONTINUE WITH PROPOSAL ON PLATFORM"
              />
            </span>
            {/* // TODO COMM HUB CHANGES */}
            <span id="mm-checkout-communication-hub">
              <BaseUiButton
                onClick={navigateToCommunicationHub}
                className="mr-2 my-3"
                color="primary"
                label="GO TO COMMUNICATION HUB"
              />
            </span>
          </Box>
        </>
      )}
    </>
  );
}
